import CtaSection from "../components/sections/CtaSection"
import IllustrationHeader from "../components/global/IllustrationHeader"
import Layout from "../components/global/Layout"
import QuoteSection from "../components/sections/QuoteSection"
import HomepageTabbedCardsSection from '../components/page-specific/Homepage/HomepageTabbedCardsSection'
import React from "react"
import ValuePropsSection from "../components/sections/ValuePropsSection"

const WaysToOrder = ({ location }) => {
    return (
        <Layout
            className="ways-to-order-page"
            location={location}
            title={"Ways to Order | Grubhub Corporate"}
            description={"From individual meals to group order, Grubhub's customizable ordering options are built to suit your needs."}
            utag_data={{
                pageGroup: "grubhub - lead generation",
                subGroup: "ways to order",
                pageName: "ways to order",
                brand: "grubhub"
            }}
        >
            <IllustrationHeader
                headline="Ordering made easy"
                subheadline="Grubhub has the most ordering options to fit any working model. Your team can order the food they love and get it delivered anywhere."
                background="radial-gradient(circle, #A0C8D1 0%, #1D858A 100%)"
                leftSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1628277582/Grubhub%20Corporate%20Website/2021/Header%20Images/Illustrated%20Headers%20/2-WaysToOrder-Homepage/DESKTOP-WaysToOrderHomepage-HeaderImage-LEFT_IMAGE.png"
                altLeftSide="Illustration of a burger with two pieces of sushi flying over."
                rightSideIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933614/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/2-WaysToOrder-Homepage/DESKTOP-WaysToOrderHomepage-HeaderImage-RIGHT_IMAGE-Citrus.png"
                altRightSide="Illustrations of a salad, a drink, a Grubhub food bag and a taco."
                mobileIllustration="https://res.cloudinary.com/grubhub-marketing/image/upload/v1632933615/Grubhub%20Corporate%20Website/2021%20%E2%80%94%20Citrus/Header%20Images/Illustrated%20Headers/2-WaysToOrder-Homepage/MOBILE-WaysToOrderHomepage-HeaderImage-Citrus.png"
                altMobile="Illustrations of a salad, a drink, a Grubhub food bag and a taco."
                ctaLink={"/get-started/?utm_source=homepage&utm_medium=button&utm_campaign=ways_to_order_button"}
                ctaText={"Get started"}
                tealiumEventCategory={"ways-to-order button"}
                tealiumEventAction={"button clicks"}
                tealiumEventLabel={"Get Started"}
            />
            <h2 style={{
                fontWeight:'bold',
                textAlign:'center',
                paddingLeft: '15px',
                paddingRight: '15px',
                marginTop:"50px"
            }}>
                The most ways to order
            </h2>
            <p style={{
                fontSize:'19px',
                textAlign:'center',
                paddingLeft: '15px',
                paddingRight: '15px',
                marginTop:"20px"
            }}>
                Easy ordering. Delicious food. Reliable delivery to wherever your team sits, with dedicated support every step of the way.
            </p>
            <HomepageTabbedCardsSection backgroundColorTabs="#FFF"/>
            <QuoteSection
                quote='"Having a corporate account has made it much easier to make sure that all employees are taken care of. They have the option to purchase individual meals or group orders and I always have someone to contact if I have a question or concern."'
                author="Giana R"
                backgroundColor="#F9F9F9"
                imageSrc="https://res.cloudinary.com/grubhub-marketing/image/upload/v1650461865/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Ways%20to%20Order%20Page/PARTNERLOGOS-GoFundMe.png"
                alt="GoFundMe logo"
                position="Workplace and Employee Experience Manager at GoFundMe"
            />
            <ValuePropsSection
                headline="Delivering happiness is our #1 priority"
                layout="horizontal"
                backgroundColor="#FFF"
                valueProps={[
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Easiest for employees",
                    subheadline:"Ordering is simple and easy. There’s no learning curve, which means your team will enjoy ordering good food from day one. Plus, Grubhub has the most ways to order, so your team can order whenever and however they want."
                },
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Best for office managers",
                    subheadline:"The most group ordering options. Controlled, reduced costs. Guaranteed on-time delivery at the lowest price or we make it right. All different ways to say: get added peace of mind with Grubhub Corporate."
                },
                {
                    imageSrc:"https://res.cloudinary.com/grubhub-marketing/image/upload/v1649347218/HERMES/2022/B2B/GHCORP/B2B%20Corp%20Website/March%208%20Handoff/Homepage/EXPORTS-Illustrations-CheckIcon.svg",
                    headline:"Built for businesses",
                    subheadline:"Since 1999, Grubhub Corporate has been proudly serving corporate clients across America. The first to feed a hybrid workforce, we bring you cost-effective and flexible solutions that fit your team’s evolving needs."
                }
                ]}
            />
            <CtaSection
                headline="Discover the industry leader in flexible meal benefits"
                to="/get-started/"
                backgroundColor="#F9F9F9"
            />
        </Layout>
    )
}

export default WaysToOrder
